import { Observable } from 'rxjs';

import { ChatMessage, CompletionCreateParams, Feedback, UnitName } from '../models/chat.model';

export abstract class ChatUseCase {
  abstract get messages$(): Observable<ChatMessage[]>;
  abstract get progress$(): Observable<boolean>;
  abstract get unitNames$(): Observable<UnitName[]>;
  abstract get predictions$(): Observable<string[]>;
  abstract get isThumbnailDisplay$(): Observable<boolean>;
  abstract get querySuggestion$(): Observable<string>;

  abstract send(params: CompletionCreateParams, userInputTime: number): Observable<never>;
  abstract reset(): void;
  abstract feedback(button: Feedback, message: ChatMessage): void;
  abstract fetchUnitNames(initial: string | null): Observable<never>;
  abstract fetchPredictionWords(words: string | null): Observable<never>;
  abstract clearPredictionWords(): void;
  abstract selectPredictions(selectedIndex: number): string;
  abstract setThumbnailSetting(isDisplay: boolean): void;
  abstract postQuerySuggestion(suggestion: string): void;
  abstract updateSuggestionScore(suggestion: string): void;
}
